import React from "react";
import HomeLandingView from "../ContentComponent/HomeLandingView";
import AboutView from "../ContentComponent/AboutView";
import HomeCard from "../components/HomeCard";
import { Box, Grid } from "@mui/material";
import "./style/home.css";
import ServicesCard from "../ContentComponent/ServicesCard";
import ContactView from "../ContentComponent/ContactView";
import BlogView from "../ContentComponent/BlogView";
import ScrollToTop from "../components/ScrollToTop";
const Home = () => {
  return (
    <div>
      <HomeLandingView />
      <Grid
        container
        spacing={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#f8f8f8",
          pl: 4,
          pr: 4,
        }}
      >
        <Grid item xl={3.8} lg={3.8} md={3.8} xs={12}>
          <HomeCard
            Icon={"/assets/head-of-a-horse-outline.png"}
            Description="Systematic process aligning resources to achieve long-term organizational goals through decision-making."
            Heading="Strategic Planning"
            CardArrow={true}
          />
        </Grid>
        <Grid item xl={3.8} lg={3.8} md={3.8} xs={12}>
          <HomeCard
            Icon={"/assets/boy.png"}
            Description="Strategic consulting: optimizing businesses through effective marketing solutions, driving growth and profit."
            Heading="Consulting & Marketing"
            CardArrow={true}
          />
        </Grid>
        <Grid item xl={3.8} lg={3.8} md={3.8} xs={12}>
          <HomeCard
            Icon={"/assets/financial-report.png"}
            Description="Financial services: managing wealth, optimizing investments, and securing financial well-being for clients."
            Heading="Financial"
            CardArrow={true}
          />
        </Grid>
      </Grid>
      <AboutView />
      <div className="service-container2">
        <Box className="service-img" sx={{ ml: 4, mr: 4 }}>
          <img src="https://slack-imgs.com/?c=1&o1=ro&url=https%3A%2F%2Ftechvera.com%2Fwp-content%2Fuploads%2F2020%2F05%2Fadults-analysis-brainstorming-1661004.jpg" alt="img"/>
        </Box>
      </div>
      <ServicesCard />
      <BlogView />
      <ContactView/>
      <ScrollToTop />
    </div>
  );
};

export default Home;
